import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';
import * as animationData from '../../../../wizard/lottie/celebration.json';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import { SeoWizardContext } from './Context';

const FinishScreen = () => {
  const { isDraft } = useContext(SeoWizardContext);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const checkPrimaryLightOptions = {
    loop: true,
    autoplay: true,
    animationData: checkPrimaryLight,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <div className="theme-wizard my-4 text-center">
      <div className={isDraft ? 'py-auto mx-auto' : 'wizard-lottie-wrapper'}>
        <div className={isDraft ? '' : 'mx-auto wizard-lottie'}>
          <Lottie
            width={isDraft ? '12rem' : ''}
            options={isDraft ? checkPrimaryLightOptions : defaultOptions}
          />
        </div>
      </div>
      <div className="mt-4">
        {isDraft ? (
          <>
            <h4 className="mb-1">Drafted SEO Project successfully!</h4>
            <p className="fs-0">
              We have updated keywords with their corresponding links.
            </p>
            <Link
              to={'/seo-project-drafts'}
              className="btn btn-outline-primary rounded-pill"
            >
              <FontAwesomeIcon icon={'reply'} /> Back to drafts
            </Link>
          </>
        ) : (
          <>
            <h4 className="mb-1">Your SEO Project is all set!</h4>
            <p className="fs-0">You will receive a mail once it has been approved.</p>
            <Link
              to={'/seo-projects'}
              className="btn btn-outline-primary rounded-pill"
            >
              <FontAwesomeIcon icon={'reply'} /> Back to all SEO Projects
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default FinishScreen;
