import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';

const AidaMonthPicker = ({
    date,
    setDate = date => {},
    placeholder = '',
    name = '',
    className = '',
    ...rest
}) => {
  return (
    <>
        <ReactDatePicker
            selected={date}
            onChange={(date) => {
              setDate(date)
            }}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            name={name || 'date'}
            className={`form-control ${className}`}
            placeholderText={placeholder || 'Select Month'}
            required
            {...rest}
            />
    </>
    );
  };

export default AidaMonthPicker;
