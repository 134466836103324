import AidaMonthPicker from 'components/common/custom-components/AidaMonthPicker';
import CallApi from 'components/common/custom-components/CallApi';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import IconButton from 'components/common/IconButton';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Modal, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useMutation } from 'react-query';

const TerminationForm = ({ seoProject, refetch, children }) => {
  const [show, setShow] = useState(false);
  const [terminationDate, setTerminationDate] = useState(null);
  const [terminationReason, setTerminationReason] = useState(null);

  const handleClose = () => {
    setTerminationDate(null);
    setTerminationReason(null);
    setShow(false);
  };

  const getLastOfMonth = (date) =>  {
    return new Date(date.getFullYear(), date.getMonth()+1, 0)
  }

  const TerminateSeoProjectMutation = useMutation(
    ['terminate-seo-project', seoProject?.id],
    formData =>
      CallApi.post(`/terminate-seo-project/${seoProject?.id}`, formData),
    {
      onSuccess: json => {
        refetch();
        handleClose();
      }
    }
  );

  const terminateSeoProject = () => {
    const formData = new FormData();

    formData.append(
      'termination_date',
      dayjs(terminationDate).format('YYYY-MM-DD')
    );
    formData.append('termination_reason', terminationReason);

    TerminateSeoProjectMutation.mutate(formData);
  };

  return (
    <>
      <span
        onClick={() => {
          setShow(true);
          if (seoProject?.seo_project_termination?.termination_date) {
            setTerminationDate(
              new Date(seoProject?.seo_project_termination?.termination_date)
            );
          }
          setTerminationReason(
            seoProject?.seo_project_termination?.termination_reason
          );
        }}
      >
        {children}
      </span>
      <Modal
        show={show}
        style={{
          backgroundColor: 'rgba(3, 0, 0, 0.5)'
        }}
        centered
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <small className="text-dark">Terminate Project</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3 pt-3 text-dark">
          <div className="mb-3">
            <small className="text-dark">
              Only submit this form if the client does not want to continue with
              Trakk
            </small>
          </div>
          <Form.Group className="mb-2">
            <div>
            <Form.Label>
              Month for last report
              <LocalStorageTooltip
                className="ms-1"
                identifier={'form-terminate-seo-project-field-termination-date'}
              />
            </Form.Label>
            </div>
            <AidaMonthPicker
              wrapperClassName="w-100"
              date={terminationDate}
              setDate={date => {
                setTerminationDate(getLastOfMonth(date))
              }}
              name="termination_date"
              disabled={TerminateSeoProjectMutation.isLoading}
              minDate={new Date()}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Reason for termination
              <LocalStorageTooltip
                className="ms-1"
                identifier={
                  'form-terminate-seo-project-field-termination-reason'
                }
              />
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={terminationReason}
              onChange={e => setTerminationReason(e.currentTarget.value)}
              name="termination_reason"
              disabled={TerminateSeoProjectMutation.isLoading}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <OverlayTrigger
            placement={'top'}
            overlay={
              seoProject?.seo_project_termination ? (
                <Tooltip>Termination notice already sent!</Tooltip>
              ) : (
                <></>
              )
            }
          >
            <span className="d-inline-block">
              <IconButton
                type="button"
                variant="outline-primary"
                className="rounded-pill"
                icon={
                  TerminateSeoProjectMutation.isLoading
                    ? 'spinner'
                    : 'paper-plane'
                }
                spin={TerminateSeoProjectMutation.isLoading}
                size="sm"
                disabled={seoProject?.seo_project_termination}
                onClick={terminateSeoProject}
              >
                Send notice of termination
              </IconButton>
            </span>
          </OverlayTrigger>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TerminationForm;
