import Accounts from 'components/aida/accounts/Accounts';
import CreateAccountWizardForm from 'components/aida/accounts/CreateAccountWizardForm';
import DraftsWizardEditForm from 'components/aida/accounts/DraftsWizardEditForm';
import DraftsWizardForm from 'components/aida/accounts/DraftsWizardForm';
import AdsAccount from 'components/aida/accounts/View/AdsAccount';
import Clients from 'components/aida/clients/Clients';
import CompetitorAnalysis from 'components/aida/competitor-analysis/CompetitorAnalysis';
import Sales from 'components/aida/modules/Sales';
import Regions from 'components/aida/regions/Regions';
import SearchQueryKeywords from 'components/aida/search-query-keywords/SearchQueryKeywords';
import SeoProjectDrafts from 'components/aida/seo-projects/Drafts';
import SeoProjectAwaitingApprovalList from 'components/aida/seo-projects/AwaitingApprovalList';

import SeoProjects from 'components/aida/seo-projects/SeoProjects';
import SeoProjectWizard from 'components/aida/seo-projects/components/wizard-forms/SeoProjectWizard';
import UserProfileSettings from 'components/aida/users/UserProfileSettings';
import Users from 'components/aida/users/Users';
import StaticPage from 'components/common/custom-components/StaticPage';
import Error403 from 'components/errors/Error403';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const MainRoutes = () => {
  return (
    <Switch>
      <Route path="/static-page" exact component={StaticPage} />
      <Route path="/profile/settings" exact component={UserProfileSettings} />
      <Route path="/keywords-selection" exact component={SearchQueryKeywords} />
      <Route path="/unauthorized" component={Error403} />
      <Route path="/users" exact component={Users} />
      <Route path="/regions" exact component={Regions} />

      <Route path="/sales" exact component={Sales} />
      <Route
        path="/sales/research-competitor"
        exact
        component={CompetitorAnalysis}
      />

      <Route path="/account" exact component={AdsAccount} />
      <Route path="/clients" exact component={Clients} />
      <Route path="/accounts" exact component={Accounts} />
      <Route path="/create-account" exact component={CreateAccountWizardForm} />
      <Route path="/accounts/drafts" exact component={DraftsWizardForm} />
      <Route
        path="/accounts/edit-draft/:id"
        exact
        component={DraftsWizardEditForm}
      />

      <Route path="/seo-projects" exact component={SeoProjects} />
      <Route path="/seo-project/:id?" exact component={SeoProjectWizard} />
      <Route path="/seo-project-drafts" exact component={SeoProjectDrafts} />
      <Route path="/seo-project-awaiting-approval" exact component={SeoProjectAwaitingApprovalList} />


      <Redirect to="/errors/404" />
    </Switch>
  );
};
export default MainRoutes;
